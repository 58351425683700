import React, { Component } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import axios from "axios";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 1,
    };
    this.handleClick = this.handleClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleClick(key) {
    console.log(key);
    this.setState({ selectedTab: Number(key) });
  }

  async onSubmit(event) {
    event.persist();
    event.preventDefault();
    let outboundEmail1 = event.target[0].value;
    let outboundEmail2 = event.target[1].value;
    console.log(event.target);
    console.log(outboundEmail1, outboundEmail2);
    let orgEmail = "@premier-roofing.com";
    if (
      outboundEmail1.includes(orgEmail) &&
      outboundEmail2.includes(orgEmail)
    ) {
      if (this.state.selectedTab === 1) {
        // this is the customer agreenment form
        let formValues = {
          form: "customerAgreement",
          supEmail: event.target[0].value,
          salesEmail: event.target[1].value,
          salesName: event.target[2].value,
          salesPhone: event.target[3].value,
          customerEmail: event.target[4].value,
          customerName: event.target[5].value,
          customerPhone: event.target[6].value,
          customerAddress: event.target[7].value,
          customerCity: event.target[8].value,
          customerState: event.target[9].value,
          customerZip: event.target[10].value,
          northSlopeDamages: event.target[11].value,
          southSlopeDamages: event.target[12].value,
          westSlopeDamages: event.target[13].value,
          eastSlopeDamages: event.target[14].value,
          otherDamages: event.target[15].value,
          lowSlopeAreas: event.target[16].checked,
          compositionShingle: event.target[17].checked,
          woodShingle: event.target[18].checked,
          metalShingle: event.target[19].checked,
          otherShingle: event.target[20].checked,
          otherShingleText: event.target[21].value,
          plywoodDecking: event.target[22].checked,
          spacedDecking: event.target[23].checked,
          otherDecking: event.target[24].checked,
          otherDeckingText: event.target[25].value,
          numberLayers: event.target[26].value,
          typeLayers: event.target[27].value,
          roofGrade: event.target[28].value,
          roofPitch: event.target[29].value,
          ridgeGradeExisting: event.target[30].value,
          valleyMaterialExisting: event.target[31].value,
          difficultAccessYes: event.target[32].checked,
          difficultAccessNo: event.target[33].checked,
          twoStoryYes: event.target[34].checked,
          twoStoryNo: event.target[35].checked,
          leakBarrierYes: event.target[36].checked,
          leakBarrierNo: event.target[37].checked,
          replacementBrand: event.target[38].value,
          replacementStyle: event.target[39].value,
          replacementRoofGrade: event.target[40].value,
          replacementRidge: event.target[41].value,
          replacementDeck: event.target[42].value,
        };
        window.alert("Submitted your agreement. Please wait for confirmation.");
        let res = await axios.post(`/api/docusign`, formValues);
        if (res.data.sent === true) {
          window.alert("Successfully Sent!");
          window.location.reload();
        } else {
          window.alert("Customer agreement failed to send.");
          window.location.reload();
        }
      } else if (this.state.selectedTab === 2) {
        // this is the COC form
        let formValues = {
          form: "COC",
          supEmail: event.target[0].value,
          salesEmail: event.target[1].value,
          customerEmail: event.target[2].value,
          customerName: event.target[3].value,
          jobNum: event.target[4].value,
        };

        window.alert("Submitted your agreement. Please wait for confirmation.");
        let res = await axios.post(`/api/docusign`, formValues);
        console.log(res);
        if (res.data.sent === true) {
          window.alert("Successfully Sent!");
          window.location.reload();
        } else {
          window.alert("COC failed to send.");
          window.location.reload();
        }
      } else if (this.state.selectedTab === 3) {
        //Material/Color Change Amendment

        let formValues = {
          form: "colorOrMaterialChange",
          supEmail: event.target[0].value,
          repEmail: event.target[1].value,
          hoEmail: event.target[2].value,
          hoName: event.target[3].value,
          jobNum: event.target[4].value,
          prevBrand: event.target[5].value,
          prevMaterial: event.target[6].value,
          prevColor: event.target[7].value,
          newBrand: event.target[8].value,
          newMaterial: event.target[9].value,
          newColor: event.target[10].value,
        };
        console.log(formValues);
        window.alert("Submitted your amendment. Please wait for confirmation.");
        let res = await axios.post(`/api/docusign`, formValues);
        console.log(res);
        if (res.data.sent === true) {
          window.alert("Successfully Sent!");
          window.location.reload();
        } else {
          window.alert("Color/ Material Change Amendment failed to send.");
          window.location.reload();
        }
      } else if (this.state.selectedTab === 4) {
        //Material/Color Change Amendment

        let formValues = {
          form: "contractorAuth",
          supEmail: event.target[0].value,
          repEmail: event.target[1].value,
          hoEmail: event.target[2].value,
          hoName: event.target[3].value,
          insName: event.target[4].value,
          claimNumber: event.target[5].value,
        };
        console.log(formValues);
        window.alert("Submitted your amendment. Please wait for confirmation.");
        let res = await axios.post(`/api/docusign`, formValues);
        console.log(res);
        if (res.data.sent === true) {
          window.alert("Successfully Sent!");
          window.location.reload();
        } else {
          window.alert("Color/ Material Change Amendment failed to send.");
          window.location.reload();
        }
      } else {
        window.alert("An unexpected Error Occurred");
      }
    } else {
      window.alert(
        "ERROR: Invalid Rep Email Supplied. Your email must belong to Premier Roofing."
      );
    }
  }

  render() {
    return (
      <Tabs defaultActiveKey={1} onSelect={(key) => this.handleClick(key)}>
        <Tab eventKey={1} title="Customer Agreement">
          <Container fluid>
            <Row>
              <Col>
                <br />
                <center>
                  <h2>Create Agreement</h2>
                </center>
                <br />
                <Form onSubmit={this.onSubmit}>
                  <Card>
                    <Card.Body>
                      <h5>Field Representative</h5>
                      <Form.Group id="supEmail" controlId="supEmail">
                        <Form.Label>Premier Supervisor Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@premier-roofing.com"
                        />
                      </Form.Group>
                      <Form.Group id="salesEmail" controlId="salesEmail">
                        <Form.Label>Premier Rep Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@premier-roofing.com"
                        />
                      </Form.Group>
                      <Form.Group id="salesName" controlId="salesName">
                        <Form.Label>Full Rep Name</Form.Label>
                        <Form.Control required placeholder="Example Rep Name" />
                      </Form.Group>
                      <Form.Group id="salesPhone" controlId="salesPhone">
                        <Form.Label>Phone Number (DID)</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="000-867-5309"
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Homeowner</h5>
                      <Form.Group controlId="customerEmail">
                        <Form.Label>Homeowner Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@email.com"
                        />
                      </Form.Group>
                      <Form.Group controlId="customerName">
                        <Form.Label>Homeowner Name</Form.Label>
                        <Form.Control required placeholder="John Doe" />
                      </Form.Group>
                      <Form.Group controlId="customerPhone">
                        <Form.Label>Customer Phone Number</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="000-000-0000"
                        />
                      </Form.Group>
                      <Form.Group controlId="customerAddress">
                        <Form.Label>Homeowner Address</Form.Label>
                        <Form.Control required placeholder="1234 Main St" />
                      </Form.Group>
                      <Form.Group controlId="customerCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control required placeholder="City" />
                      </Form.Group>
                      <Form.Row>
                        <Form.Group as={Col} controlId="customerState">
                          <Form.Label>State</Form.Label>
                          <Form.Control required placeholder="State" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="customerZip">
                          <Form.Label>Zip</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            placeholder="000000"
                          />
                        </Form.Group>
                      </Form.Row>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Damages Found</h5>
                      <Form.Group controlId="northSlopeDamages">
                        <Form.Label>North Slope</Form.Label>
                        <Form.Control as="textarea" rows="2" />
                      </Form.Group>
                      <Form.Group controlId="southSlopeDamages">
                        <Form.Label>South Slope</Form.Label>
                        <Form.Control as="textarea" rows="2" />
                      </Form.Group>
                      <Form.Group controlId="westSlopeDamages">
                        <Form.Label>West Slope</Form.Label>
                        <Form.Control as="textarea" rows="2" />
                      </Form.Group>
                      <Form.Group controlId="eastSlopeDamages">
                        <Form.Label>East Slope</Form.Label>
                        <Form.Control as="textarea" rows="2" />
                      </Form.Group>
                      <Form.Group controlId="otherDamages">
                        <Form.Label>Other Damages</Form.Label>
                        <Form.Control as="textarea" rows="2" />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Existing Roof Specs</h5>
                      <Form.Group id="existingSpecs.lowSlope">
                        <Form.Check
                          inline
                          type="checkbox"
                          label="Low Slope Areas"
                        />
                      </Form.Group>
                      <hr />
                      <Form.Group id="existingSpecs.shingle">
                        <h6>Existing Roof Material</h6>
                        <Form.Check
                          type="checkbox"
                          inline
                          label="Composition Shingle"
                        />
                        <Form.Check type="checkbox" inline label="Wood" />
                        <Form.Check type="checkbox" inline label="Metal" />
                        <Form.Check type="checkbox" inline label="Other:" />
                        <Form.Control placeholder="Other Material Type" />
                      </Form.Group>
                      <hr />
                      <Form.Group id="existingSpecs.decking">
                        <h6>Existing Decking Material</h6>
                        <Form.Check
                          type="checkbox"
                          inline
                          label="Plywood/OSB"
                        />
                        <Form.Check type="checkbox" inline label="Spaced" />
                        <Form.Check type="checkbox" inline label="Other:" />
                        <Form.Control placeholder="Other Material Type" />
                      </Form.Group>
                      <hr />
                      <h6>Existing Layers</h6>
                      <Form.Group controlId="numberLayers">
                        <Form.Label>Number of Layers</Form.Label>
                        <Form.Control type="number" placeholder="Ex: 2" />
                      </Form.Group>
                      <Form.Group controlId="typeLayers">
                        <Form.Label>Layer Types</Form.Label>
                        <Form.Control placeholder="3-Tab" />
                      </Form.Group>
                      <hr />
                      <h6>Pitch and Grade</h6>
                      <Form.Group controlId="roofGrade">
                        <Form.Label>Existing Grade</Form.Label>
                        <Form.Control as="select">
                          <option>20 year</option>
                          <option>30 year</option>
                          <option>50 year</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="roofPitch">
                        <Form.Label>Existing Pitch</Form.Label>
                        <InputGroup>
                          <Form.Control aria-label="pitch" type="number" />
                          <InputGroup.Append>
                            <InputGroup.Text id="pitchLabel">
                              {" "}
                              / 12
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group controlId="ridgeGradeExisting">
                        <Form.Label>Existing Ridge Grade</Form.Label>
                        <Form.Control placeholder="Low Profile" />
                      </Form.Group>
                      <Form.Group controlId="valleyMaterialExisting">
                        <Form.Label>Existing Valley Material</Form.Label>
                        <Form.Control placeholder="None" />
                      </Form.Group>
                      <hr />
                      <Form.Row>
                        <Form.Group as={Col} id="existingSpecs.difficultAccess">
                          <h6>Difficult Access</h6>
                          <Form.Check
                            type="checkbox"
                            inline
                            label="Y"
                            size="lg"
                          />
                          <Form.Check
                            type="checkbox"
                            inline
                            label="N"
                            size="lg"
                          />
                        </Form.Group>
                        <hr />
                        <Form.Group as={Col} id="existingSpecs.twoStory">
                          <h6>Two Story</h6>
                          <Form.Check
                            type="checkbox"
                            inline
                            label="Y"
                            size="lg"
                          />
                          <Form.Check
                            type="checkbox"
                            inline
                            label="N"
                            size="lg"
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Group id="existingSpecs.leakBarrier">
                        <h6>Existing Leak Barrier</h6>
                        <Form.Check
                          type="checkbox"
                          inline
                          label="Y"
                          size="lg"
                        />
                        <Form.Check
                          type="checkbox"
                          inline
                          label="N"
                          size="lg"
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Replacement Roof Details</h5>
                      <Form.Group controlId="replacementBrand">
                        <Form.Label>Brand</Form.Label>
                        <Form.Control defaultValue="GAF" />
                      </Form.Group>
                      <Form.Group controlId="replacementStyle">
                        <Form.Label>Style</Form.Label>
                        <Form.Control defaultValue="Timberline HD" />
                      </Form.Group>
                      <Form.Group controlId="replacementRoofGrade">
                        <Form.Label>Roof Grade</Form.Label>
                        <Form.Control as="select">
                          <option>50 year</option>
                          <option>20 year</option>
                          <option>30 year</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="replacementRidge">
                        <Form.Label>Ridge</Form.Label>
                        <Form.Control defaultValue="High Profile" />
                      </Form.Group>
                      <Form.Group controlId="replacementDeck">
                        <Form.Label>Deck Protection</Form.Label>
                        <Form.Control defaultValue="IWS/Felt" />
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                      <center>
                        <small>
                          Ensure all details are correct before submittal.
                        </small>
                      </center>
                    </Card.Footer>
                  </Card>
                  <br />
                  <Button variant="primary" type="submit" size="lg" block>
                    Create + Send Agreement
                  </Button>
                </Form>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </Tab>

        <Tab eventKey={2} title="COC">
          <Container fluid>
            <Row>
              <Col>
                <br />
                <center>
                  <h2>Create COC Form</h2>
                </center>
                <br />
                <Form onSubmit={this.onSubmit}>
                  <Card>
                    <Card.Body>
                      <h5>Field Representative</h5>
                      <Form.Group id="supEmail" controlId="supEmail">
                        <Form.Label>Premier Supervisor Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@premier-roofing.com"
                        />
                      </Form.Group>
                      <Form.Group id="salesEmail" controlId="salesEmail">
                        <Form.Label>Premier Rep Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@premier-roofing.com"
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Homeowner</h5>
                      <Form.Group controlId="customerEmail">
                        <Form.Label>Homeowner Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@email.com"
                        />
                      </Form.Group>
                      <Form.Group controlId="customerName">
                        <Form.Label>Homeowner Name</Form.Label>
                        <Form.Control required placeholder="John Doe" />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Job Number</h5>
                      <Form.Group controlId="jobNum">
                        <Form.Label>Job Number</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="000000"
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Button variant="primary" type="submit" size="lg" block>
                    Create + Send Form
                  </Button>
                </Form>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </Tab>

        <Tab eventKey={3} title="Color / Material Change">
          <Container fluid>
            <Row>
              <Col>
                <br />
                <center>
                  <h2>Create Color / Material Change Amendment Contract</h2>
                </center>
                <br />
                <Form onSubmit={this.onSubmit}>
                  <Card>
                    <Card.Body>
                      <h5>Field Representative</h5>
                      <Form.Group id="supEmail" controlId="supEmail">
                        <Form.Label>Premier Supervisor Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@premier-roofing.com"
                        />
                      </Form.Group>
                      <Form.Group id="repEmail" controlId="repEmail">
                        <Form.Label>Premier Rep Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@premier-roofing.com"
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Homeowner</h5>
                      <Form.Group controlId="hoEmail">
                        <Form.Label>Homeowner Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@email.com"
                        />
                      </Form.Group>
                      <Form.Group controlId="hoName">
                        <Form.Label>Homeowner Name</Form.Label>
                        <Form.Control required placeholder="John Doe" />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Job Number</h5>
                      <Form.Group controlId="jobNum">
                        <Form.Label>Job Number</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="000000"
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Previously Selected Material and Color</h5>
                      <Form.Group controlId="prevBrand">
                        <Form.Label>Brand</Form.Label>
                        <Form.Control required placeholder="i.e. GAF" />
                      </Form.Group>
                      <Form.Group controlId="prevMaterial">
                        <Form.Label>Material</Form.Label>
                        <Form.Control
                          required
                          placeholder="i.e. Timberline HDZ"
                        />
                      </Form.Group>
                      <Form.Group controlId="prevColor">
                        <Form.Label>Color</Form.Label>
                        <Form.Control
                          required
                          placeholder="i.e. Weathered Wood"
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>New Material and Color</h5>
                      <Form.Group controlId="newBrand">
                        <Form.Label>Brand</Form.Label>
                        <Form.Control required placeholder="i.e. GAF" />
                      </Form.Group>
                      <Form.Group controlId="newMaterial">
                        <Form.Label>Material</Form.Label>
                        <Form.Control
                          required
                          placeholder="i.e. Timberline HDZ"
                        />
                      </Form.Group>
                      <Form.Group controlId="newColor">
                        <Form.Label>Color</Form.Label>
                        <Form.Control required placeholder="i.e. Hickory" />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Button variant="primary" type="submit" size="lg" block>
                    Create + Send Amendment
                  </Button>
                </Form>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </Tab>
        <Tab eventKey={4} title="Contractor Auth">
          <Container fluid>
            <Row>
              <Col>
                <br />
                <center>
                  <h2>Create Contractor Authorization Form</h2>
                </center>
                <br />
                <Form onSubmit={this.onSubmit}>
                  <Card>
                    <Card.Body>
                      <h5>Field Representative</h5>
                      <Form.Group id="supEmail" controlId="supEmail">
                        <Form.Label>Premier Supervisor Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@premier-roofing.com"
                        />
                      </Form.Group>
                      <Form.Group id="repEmail" controlId="repEmail">
                        <Form.Label>Premier Rep Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@premier-roofing.com"
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Homeowner</h5>
                      <Form.Group controlId="hoEmail">
                        <Form.Label>Homeowner Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="example@email.com"
                        />
                      </Form.Group>
                      <Form.Group controlId="hoName">
                        <Form.Label>Homeowner Name</Form.Label>
                        <Form.Control required placeholder="John Doe" />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Card>
                    <Card.Body>
                      <h5>Insurance Information</h5>
                      <Form.Group controlId="insName">
                        <Form.Label>Insurance Company</Form.Label>
                        <Form.Control required placeholder="State Farm" />
                      </Form.Group>
                      <Form.Group controlId="claimNumber">
                        <Form.Label>Claim Number</Form.Label>
                        <Form.Control required placeholder="Claim Number" />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                  <br />
                  <Button variant="primary" type="submit" size="lg" block>
                    Create + Send Auth Form
                  </Button>
                </Form>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </Tab>
      </Tabs>
    );
  }
}

export default App;
